<template>
  <section id="dashboard-ecommerce" v-if="$can('read','recruiter_report') || $can('read','report')">
    
    <div>
     
        <div class="text-center"><h3>Recruiter Work Tracker</h3></div>

        <b-card>
          <div>
            <b-row>
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label="Start Date"
                  label-for="start-date"
                >
                  <flat-pickr
                    v-model="startDate"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                  />
                  <small v-if="!startDate" class="text-danger">Start Date is Mandatory</small>
                </b-form-group>
              </b-col>    
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label="End Date"
                  label-for="end-date"
                >
                  <flat-pickr
                    v-model="endDate"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                  />
                  <small v-if="!endDate" class="text-danger">End Date is Mandatory</small>
                </b-form-group>
              </b-col>
              <b-col md="4" xl="4" xs="4" class="mb-1">
                <b-form-group
                  label=""
                  label-for="">
                  <b-button variant="outline-primary" value="submit" id="submit" type="submit" width="100%" @click="getReport" style="margin-top: 20px">Get Report</b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <div v-if="loading">
                <div class="text-center">
                  <b-spinner variant="primary" label="Text Centered" />
                </div>
            </div>
            <div v-if="!loading && compiledData ">
               <a :href="downloadReport" :download="filename"><span class="text-danger">{{ reportStatus }}</span></a> 
              <div id="work-tracker" class="ml-2 mr-2" v-for="d in compiledData" :key="d.am_name">
                
                
                  <b-row class="mt-2" style="width: 100%;" >
                    <b-col  class="text-center" style="background-color: #00a99d;  border: 1px solid #5e5873;">
                      <h5 style="margin: 0px !important; color: #fff !important;">{{ d.am_name }}</h5>
                    </b-col>
                  </b-row>
                  <b-row style="width: 100%">
                    <div class="text-center col-my10" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Recruiter</h6>
                    </div>
                    <div  class="text-center col-my14" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Client</h6>
                    </div>
                    <div  class="text-center col-my16" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Position Name</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Total Contact</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Not Interested</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Not Relevant</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Not Answered</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Not Reachable</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Wrong Number</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Call Back</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Duplicate</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Profile Shared</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Success %</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Total</h6>
                    </div>
                  </b-row>
                  <b-row style="width: 100%"  v-for="(r,index) in d.data" :key="`r-${index}`">
                    <div  class="text-center col-my10 text-v-h-center" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">{{ r.rec_name }}</h6>
                    </div>
                    <div  class="text-center col-my84" style="margin: 0px !important; border: 1px solid #5e5873;">
                      <b-row style="margin: 0px !important" v-for="(t, index) in r.tracker" :key="`t-${index}`">
                        <div class="text-center col-my17" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                          <h6 style="margin: 0px !important;">{{ t.Client }}</h6>
                        </div>
                        <div class="text-center col-my20" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                          <h6 style="margin: 0px !important;">{{ t.PositionName }}</h6>
                        </div>
                        <div class="text-center col-my7" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                          <h6 style="margin: 0px !important;">{{ t.TotalContact }}</h6>
                        </div>
                        <div class="text-center col-my7" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                          <h6 style="margin: 0px !important;">{{ t.NotInterested }}</h6>
                        </div>
                        <div class="text-center col-my7" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                          <h6 style="margin: 0px !important;">{{ t.NotRelevant }}</h6>
                        </div>
                        <div class="text-center col-my7" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                          <h6 style="margin: 0px !important;">{{ t.NotAnswered }}</h6>
                        </div>
                        <div class="text-center col-my7" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                          <h6 style="margin: 0px !important;">{{ t.NotReachable }}</h6>
                        </div>
                        <div class="text-center col-my7" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                          <h6 style="margin: 0px !important;">{{ t.WrongNumber }}</h6>
                        </div>
                        <div class="text-center col-my7" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                          <h6 style="margin: 0px !important;">{{ t.CallBack}}</h6>
                        </div>
                        <div class="text-center col-my7" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                          <h6 style="margin: 0px !important;">{{ t.Duplicate}}</h6>
                        </div>
                        <div class="text-center col-my7" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                          <h6 style="margin: 0px !important;">{{ t.ProfileShared }}</h6>
                        </div>
                        <div class="text-center col-my7" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                          <h6 style="margin: 0px !important;">{{ t.Success }} %</h6>
                        </div>
                      </b-row>
                    </div>
                    <div  class="text-center col-my6 text-v-h-center" style="margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;" v-if="r.total.total"> {{ r.total.total }}</h6>
                      <h6 style="margin: 0px !important;" v-else> {{ r.total }}</h6>                  
                    </div>
                  </b-row>
                  <b-row style="width: 100%">
                    <div  class="text-center col-my40" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">Total</h6>
                    </div>
                    <div class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">{{ d.TC }}</h6>
                    </div>
                    <div class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">{{ d.NI }}</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">{{ d.NR }}</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">{{ d.NA }}</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">{{ d.NR1 }}</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">{{ d.WN }}</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">{{ d.CB }}</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">{{ d.D }}</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">{{ d.PS }}</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">{{ d.S }}</h6>
                    </div>
                    <div  class="text-center col-my6" style="background-color: #e1fffd; margin: 0px !important; padding: 5px; border: 1px solid #5e5873;">
                      <h6 style="margin: 0px !important;">{{ d.PS }}</h6>
                    </div>
                    
                  </b-row>
                
              </div>
            </div>
            
            <div v-if="!loading && !compiledData ">
              <span class="text-danger">{{ reportStatus }}</span>
            </div>   
          </div>
        </b-card>  
      </div>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BButton, BFormGroup, BSpinner } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

import reportService from '@/services/report-service'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BSpinner,
    flatPickr
    
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      tracker: '',
      compiledData: [],
      loading: false,
      downloadReport: '',
      filename: '',
      reportStatus: '',
      userData: JSON.parse(localStorage.getItem('userData'))
    }
  },
  created() {
    this.startDate = moment().locale("en").add(-1, 'days').format("YYYY-MM-DD")
    this.endDate = moment().locale("en").add(0, 'days').format("YYYY-MM-DD")
    //this.getReport();
  },
  methods: {
    getReport() {
      // if(!this.$can('read','recruiter_report') && !this.$can('read','report')) {
      //   return
      // }
      this.compiledData = []
      this.tracker = []
      if (this.startDate === '' || this.endDate === '') {
        this.reportStatus = 'Please Select Dates'
      } else {
        this.loading = true
        console.log(this.startDate, this.endDate, moment().locale("en").format("YYYY-MM-DD"))
        //console.log('DATE CHECK', new Date(this.startDate) < new Date(moment().locale("en").format("YYYY-MM-DD")))

        if (new Date(this.startDate) <= new Date(moment().locale("en").format("YYYY-MM-DD")) && (new Date(this.startDate) <= new Date(this.endDate))) {
          reportService.getRecTracker(this.startDate, this.endDate, this.userData.emp_id).then( res => {
            if (res.status === "OK") {
              this.reportStatus = ''
              this.compiledData = res.data

              console.log(this.compiledData)
              //this.downloadReport = 'data:application/xlsx;base64,' + res.file
              this.filename = res.filename
              const a = document.createElement('a')
              a.href = 'data:application/xlsx;base64,' + res.file 
              a.download = `${this.filename}` // File name Here
              a.click()

              // if(this.userData.emp_id.includes("SHV-")) {
              //   console.log('inside vendor check')
              //   //check role
              //   if(this.userData.roles[0].includes('account-manager')) {
              //     this.compiledData = res.data.filter(item => item.am_name === this.userData.first_name + ' ' + this.userData.last_name)
              //   }
              //   console.log(this.compiledData)
              // }
              // else {
              //   this.compiledData = res.data
              //   this.filename = res.filename
              //   const a = document.createElement('a')
              //   a.href = 'data:application/xlsx;base64,' + res.file 
              //   a.download = `${this.filename}` // File name Here
              //   a.click()

              //   this.loading = false

              //   if(!this.compiledData) {
              //     this.reportStatus = 'No Data found for selected dates'
              //   }
              // }

              this.loading = false

              if(!this.compiledData) {
                this.reportStatus = 'No Data found for selected dates'
              }
            } else if ((res.status === 401 ) || (res.status === 403 )) {
              window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              this.reportStatus = 'Session Expired... Redirecting to Login Page'
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
            } else if ((res.status === 400 ) ) {
              this.reportStatus = 'No Data Found for Selected Dates'
              this.loading = false
              this.compiledData = null
            }
            else if ((res.status === 500 ) ) {
              this.reportStatus = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
              this.loading = false
              this.compiledData = null
            }
            else if ((res.status === 504 ) ) {
              this.reportStatus = 'ERROR: ' + res.data.message + '  Connection Timeout Error, Kindly contact Admin.'
              this.loading = false
              this.compiledData = null
            }
            
          })
        } else if (new Date(this.startDate) > new Date(this.endDate)) {
          this.loading = false
          this.reportStatus = 'Start Date cannot be greater than End Date!!!'
        }
          else {
          this.loading = false
          this.reportStatus = 'Start Date cannot be greater than today!!!'
        }
      }  
    },
    numDaysBetween(d1, d2) {
        var diff = Math.abs(d1.getTime() - d2.getTime());
        return diff / (1000 * 60 * 60 * 24);
    }  
    // compileReport() {
    //   const AM = this.tracker.AM
    //   const AM_REC = this.tracker.AM_REC
    //   const tracker = this.tracker.tracker
    //   const am_data = this.tracker.am_tracker
    //   console.log('AM',AM)
    //   console.log('tracker',tracker)
    //   let rawData = {}
      
    //   if(tracker.length > 0 ) {
      
    //     for (var i = 0; i < AM.length; i++) {
    //       let accountManager = AM[i].title
    //       //console.log(accountManager)
    //       let recs = {}
    //       recs = AM_REC.filter(item => item.manager === accountManager)

    //       let am_tracker = am_data.filter(item => item.Manager === accountManager)
    //       console.log(accountManager, am_tracker, am_tracker.length)
    //       //console.log(recs)
    //       rawData = {
    //         am_name: accountManager,
    //         data: [],
    //         NI: am_tracker.length > 0 ? parseInt(am_tracker[0].NotInterested) : 0,
    //         NR: am_tracker.length > 0 ? parseInt(am_tracker[0].NotRelevant) : 0,
    //         NR1: am_tracker.length > 0 ? parseInt(am_tracker[0].NotReachable) : 0,
    //         NA: am_tracker.length > 0 ? parseInt(am_tracker[0].NotAnswered) : 0,
    //         WN: am_tracker.length > 0 ? parseInt(am_tracker[0].WrongNumber) : 0,
    //         CB: am_tracker.length > 0 ? parseInt(am_tracker[0].CallBack) : 0,
    //         PS: am_tracker.length > 0 ? parseInt(am_tracker[0].ProfileShared) : 0,
    //         TC: am_tracker.length > 0 ? parseInt(am_tracker[0].TotalContact) : 0,
    //         S: am_tracker.length > 0 ? ((parseInt(am_tracker[0].ProfileShared) * 100)/parseInt(am_tracker[0].TotalContact)).toFixed(2) + " %" : 0 + " %",

    //       }
    //       let add_am_in_recs = {
    //         manager : accountManager,
    //         recruiter : accountManager
    //       }
          
    //       recs.push(add_am_in_recs)
    //       console.log('after am add to recs', recs)
          
    //       for (var j=0; j < recs.length; j++) {
    //         let recruiter = recs[j].recruiter

    //         let rec_tracker = tracker.filter(item => item.Recruiter === recruiter )
    //         let rec_total = ''
            
    //         if(rec_tracker.length === 1 ) {
    //           rec_total = parseInt(rec_tracker[0].TotalContact)
    //         } else if(rec_tracker.length > 1 ) {
            
    //           rec_total = rec_tracker.reduce(function (acc, obj) { return parseInt(acc) + parseInt(obj.ProfileShared); }, 0); 
                
    //         } else {
    //           rec_total = 0
    //         }

            
    //         rec_tracker = {
    //           rec_name : recruiter,
    //           tracker : rec_tracker,
    //           total : rec_total,
    //           rowspan : rec_tracker.length
    //         }

    //         rawData.data.push(rec_tracker)
            
    //       }
          
    //       this.compiledData.push(rawData)

    //     }
    //   } else {
    //     this.loading = false
    //   }
      
    //   console.log('compiledData', this.compiledData)
    //   this.loading = false
    // }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
table, td {
  border: 1px solid rgb(97, 97, 97);
  border-collapse: collapse;
}
.col-my10 {
  width: 9% !important;
}
.col-my14 {
  width: 12% !important;
}
.col-my16 {
  width: 13% !important;
}
.col-my6 {
  width: 6% !important;
}
.col-my84 {
  width: 85% !important;
}
.col-my17 {
  width: 14.1% !important;
}
.col-my20 {
  width: 15.3% !important;
}
.col-my7 {
  width: 7.06% !important;
}
.col-my40 {
  width: 34% !important;
}
.text-v-h-center {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}
</style>
